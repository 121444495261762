import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.value !== undefined && _vm.value !== null)?_c(VBtn,_vm._g(_vm._b({attrs:{"id":"history-button","elevation":"0","fab":"","x-small":!_vm.isSelect}},'v-btn',attrs,false),on),[_c(VIcon,{class:'small-icon' + _vm.computedClasses,style:(_vm.customStyles ? _vm.customStyles : '')},[_vm._v(" "+_vm._s(_vm.icons.mdiHistory)+" ")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_c('text-area',{ref:"textArea",attrs:{"value":_vm.computedValue,"label":("Previous input DOS: " + _vm.computedDos),"input-history-called":"","readonly":""}})],1),_c(VDivider),_c(VCardActions,[_c('btn',{attrs:{"color":"secondary","label":"Copy","icon":_vm.icons.mdiContentCopy},on:{"click":_vm.copyText}}),_c(VSpacer),_c('btn',{attrs:{"color":"primary","label":"Close"},on:{"click":function($event){_vm.dialog = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }