<template>
  <v-checkbox
    v-model="selectedValue"
    :label="label"
    :value="checkBoxValue"
    :rules="computedRules"
    :disabled="disabled"
    :hide-details="hideDetails || simple"
    :class="`${noteRequiredField ? 'required-field': ''} ${simple ? 'simple-checkbox': ''} ${stack ? 'stack-checkbox': ''}`"
    :ripple="false"
    @change="$emit('change', !!selectedValue); $emit('input', !!selectedValue)"
    @click="$emit('click', $event)"
  >
    {{ selectedValue }}
    <template
      #label
    >
      <div
        v-if="noteRequiredField"
      >
        <strong
          v-if="!disabled"
          class="error--text"
        >*&nbsp;</strong>
      </div>
      <input-history
        :title="label"
        :value="previousValue"
        :dos="previousValueDos"
      >
      </input-history>
      {{ label }}
    </template>
  </v-checkbox>
</template>

<script>
import InputHistory from '@/components/elements/InputHistory.vue'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    stack: {
      type: Boolean,
      default: false,
    },
    previousValue: {
      type: [String, Boolean, Number],
      default: null,
    },
    previousValueDos: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedValue: this.value,
      checkBoxValue: true,
    }
  },
  computed: {
    noteRequiredField() {
      return !this.disabled && this.required && !this.selectedValue
    },
    computedRules() {
      return (this.required && !this.disabled) ? this.$validationRules.required : []
    },
  },
  watch: {
    value() {
      this.selectedValue = this.value
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
.v-input.v-input--checkbox {
  &.simple-checkbox {
    margin: 0 !important;
    padding: 0 8px !important;
    .v-input__control {
      margin-bottom: 0px !important;
      .primary--text {
        color: var(--v-secondary-base) !important;
        caret-color: var(--v-secondary-base) !important;
      }
    }
  }
  &.stack-checkbox {
    .v-input__slot {
      display: inline-block;
      text-align: center;
      .v-label {
        top: -6px;
        font-size: 15px;
        white-space: nowrap;
        display: block;
      }
    }
  }
}
</style>
