import CountryCodes from '@/assets/lib/CountryCodes.json';
import Vue from 'vue';

const transformedCountryCodes = CountryCodes.map(country => {
  return {
    id: "(" + country.dial_code + ") " + country.code,
    value: country.dial_code,
    cleanValue: country.dial_code.slice(1)
  };
});

Object.defineProperties(Vue.prototype, {
  $countryCodes: {
    get() {
      return {
        transformedCountryCodes,
        CountryCodes,
      }
    }
  }
});
