<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="value !== undefined && value !== null"
        id="history-button"
        elevation="0"
        fab
        :x-small="!isSelect"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          :class="'small-icon' + computedClasses"
          :style="customStyles ? customStyles : ''"
        >
          {{ icons.mdiHistory }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <text-area
          ref="textArea"
          :value="computedValue"
          :label="`Previous input DOS: ${computedDos}`"
          input-history-called
          readonly
        >
        </text-area>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <btn
          color="secondary"
          label="Copy"
          :icon="icons.mdiContentCopy"
          @click="copyText"
        ></btn>
        <v-spacer></v-spacer>
        <btn
          color="primary"
          label="Close"
          @click="dialog = false"
        >
        </btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiHistory,
  mdiContentCopy,
} from '@mdi/js'

export default {
  props: {
    value: {
      type: [String, Number, Boolean, Array, null],
      default: null,
    },
    dos: {
      type: [String, null],
      default: null,
    },
    title: {
      type: [String, null],
      default: null,
    },
    isTextarea: {
      type: Boolean,
      default: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    prepended: {
      type: Boolean,
      default: false,
    },
    customStyles: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      icons: {
        mdiHistory,
        mdiContentCopy,
      },
    }
  },
  computed: {
    computedValue() {
      if (this.value) {
        if (typeof this.value === 'boolean') return this.value ? 'Yes' : 'No'
        if (typeof this.value === 'object') return this.value ? this.value.join(', ') : null
      }

      return this.value
    },
    computedDos() {
      if (!this.dos) return ''

      return this.$date(this.dos).format('MM/DD/YYYY')
    },
    computedClasses() {
      let classes = ''
      if (this.prepended) classes += ' prepended'

      return classes
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    copyText() {
      try {
        this.$nextTick(() => {
          const textArea = this.$refs.textArea?.$el.querySelector('textarea')

          if (textArea) {
            textArea.select()
            textArea.setSelectionRange(0, textArea.value.length) // For mobile devices

            navigator.clipboard
              .writeText(textArea.value)
              .then(() => {
                console.log('Text copied to clipboard: ' + textArea.value)
                this.dialog = false
              })
              .catch(err => {
                console.error('Failed to copy text: ', err)
              })
          } else {
            console.error('Text area ref not found')
          }
        })
      } catch (error) {
        console.warn(error)
      }
    },
  },
}
</script>
<style lang="scss">
  #history-button {
    margin-right: 5px;
    margin-bottom: 3px;
    background-color: rgba(139, 139, 139, 0);
    height: 10px;
    width: 20px;
  }

  .prepended {
    z-index: 1;
  }

</style>
