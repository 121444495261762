import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

const initialState = {
  items: [],
  error: false,
}

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters: {
    getField,
    getById(state) {
      return (id, idField = 'id') => state.items.find(item => item[idField] === id) || {}
    },
    facilities(state) {
      if (state.error) return []

      return state.items
    },
    maxFacilities(state, getters, rootState) {
      const user = rootState.auth.user // access the root-level 'auth' module directly
      return user.is_superuser ? 30 : 5  // If user type is Admin, allow up to 30 facilities. Max of 5 for regular users.
    }
  },
  mutations: {
    updateField,
    RESET_STATE: state => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
    SET_FACILITIES(state, { data }) {
      state.items = data
    },
  },
  actions: {
    loadFacilities({ commit }) {
      commit('encounters/SET_SYNCING_TXT', `Fetching facility data`, { root: true})
      return Vue.axios.get('place-of-services.json')
        .then(response => {
          if (response.data.status === 'Success') {
            commit('SET_FACILITIES', Vue.prototype.$custom.deepFreeze(response.data))

            return true
          }

          return response.data.message ? response.data.message : 'Download facilities - Unknown error'
        })
        .catch(e => Vue.prototype.$custom.processCommError(e, 'download facilities'))
    },
  },
}
