import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

// axios.defaults.timeout = 120000
axios.defaults.timeout = 240000 // 240 secs
Vue.use(VueAxios, axios)

export default {
  root: axios.defaults.baseURL,
}
