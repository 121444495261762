import modules from '@/store/modules/index'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import localForage from 'localforage'
import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import VuexPersistence from 'vuex-persist'
import { decryptData, encryptData, getEncryptionKey } from '@/plugins/storage-encryption'
import app from './app'
import router from '@/router'

// Create iDBs that will hold complete encounters and patients data.
export const fullEncounters = localForage.createInstance({
  name: 'full-encounters',
})
export const fullPatients = localForage.createInstance({
  name: 'full-patients',
})

// iDB that always syncs with the state.
export const localForageStore = localForage.createInstance({
  name: 'swc-store',
})

// Add any additional modules that you want to persist to the modules array
const vuexLocal = new VuexPersistence({
  storage: localForageStore,
  modules: [
    'auth'
    , 'users'
    , 'patients'
    , 'facilities'
    , 'encounters'
    , 'comments'
  ],
  asyncStorage: true,
  restoreState: async (key, storage) => {
    try {
      let data = await storage.getItem(key) || {}

      // Current user's ID
      const currentUserId = data?.auth?.user?.id
      // Modify patients data
      data = Object.keys(data).reduce((acc, patientKey) => {
        const patient = data[patientKey]

        // Check if patient has 'items' property and it's an array
        if (patient?.items && Array.isArray(patient.items)) {
          // Freeze attachments and treatments from signed encounters
          patient.items.forEach(Vue.prototype.$custom.freezeAttachmentsAndTreatments)

        }

        acc[patientKey] = patient
        return acc
      }, {})

      // Modify encounters data
      data = Object.keys(data).reduce((acc, encounterKey) => {
        const encounter = data[encounterKey]

        // Check if encounter has 'items' property and it's an array
        if (encounter?.items && Array.isArray(encounter.items)) {
          for (const item of encounter.items) {
            // Check if item is signed, synced, and not created by the current user.
            if (item?.is_signed && item?.is_synced && item?.created_by_user_id !== currentUserId) {
              Vue.prototype.$custom.deepFreeze(item)
            }
          }
        }

        acc[encounterKey] = encounter
        return acc
      }, {})

      // Freeze facilities data
      if (data.facilities) {
        Vue.prototype.$custom.deepFreeze(data.facilities)
      }

      // Freeze users data
      if (data.users) {
          Vue.prototype.$custom.deepFreeze(data.users)
      }

      return data
    } catch (error) {
      console.error('Error retrieving data on restoreState:', error)
      throw error
    }
  },
})

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    online: true,
    loading: true,
    isDecrypted: true,
    recordsToSync: 0,
    notifications: {
      snackbar: {},
    },
  },
  mutations: {
    setDecrypted(state, status) {
      state.isDecrypted = status
    },
    SET_RECORDS_TO_SYNC(state, amount) {
      state.recordsToSync = amount
    },
    updateField,
    activateSnackbar(state, notification) {
      Vue.set(state.notifications, 'snackbar', {
        ...state.notifications.snackbar,
        ...notification,
      })
    },
    SET_LOADING: (state, value) => {
      state.loading = value
    },
  },
  getters: {
    getField,
    isOnline(state) {
      return state.online === true
    },
    recordsToSync(state) {
      return state.recordsToSync || localStorage.getItem('recordsToSync')
    },
  },
  actions: {
    async decryptState({ commit }) {
      try {
        const key = await getEncryptionKey()

        if (key) {
          const currentState = await store.dispatch('getState')
          const lf = await store.dispatch('getLocalForage')

          if (currentState.auth?.user?.iv && currentState.auth?.user?.encrypted) {
            currentState.auth.user = await decryptData(key, currentState.auth.user.iv, currentState.auth.user.encrypted)
          }

          if (currentState.comments?.items?.iv && currentState.comments?.items?.encrypted) {
            currentState.comments.items = await decryptData(key, currentState.comments.items.iv, currentState.comments.items.encrypted)
          }
          if (currentState.encounters?.items?.iv && currentState.encounters?.items?.encrypted) {
            currentState.encounters.items = await decryptData(key, currentState.encounters.items.iv, currentState.encounters.items.encrypted)
          }
          if (currentState.facilities?.items?.iv && currentState.facilities?.items?.encrypted) {
            currentState.facilities.items = await decryptData(key, currentState.facilities.items.iv, currentState.facilities.items.encrypted)
          }
          if (currentState.patients?.items?.iv && currentState.patients?.items?.encrypted) {
            currentState.patients.items = await decryptData(key, currentState.patients.items.iv, currentState.patients.items.encrypted)
          }
          if (currentState.users?.items?.iv && currentState.users?.items?.encrypted) {
            currentState.users.items = await decryptData(key, currentState.users.items.iv, currentState.users.items.encrypted)
          }

          await lf.setItem('vuex', currentState)
            .then(() => commit('setDecrypted', true))
        }
      } catch (error) {
        commit('setDecrypted', false)
        console.log('Error while decrypting state: ', error)
      }
    },
    async setRecordsToSync({ dispatch, commit }) {
      // Only proceed if patients, encounters, or comments are NOT encrypted.
      const currentState = await store.dispatch('getState')
      if (currentState.patients.items.iv || currentState.encounters.items.iv || currentState.comments.items.iv) {
        return
      }
      const recordsToSync = await dispatch('getRecordsToSync')
      localStorage.setItem('recordsToSync', recordsToSync)
      commit('SET_RECORDS_TO_SYNC', recordsToSync)
    },
    async getRecordsToSync() {
      const currentState = await store.dispatch('getState')

      return currentState.patients.items.reduce((count, item) => count + (item.updated ? 1 : 0), 0)
      + currentState.encounters.items.reduce((count, item) => count + (!item.is_synced ? 1 : 0), 0)
      + currentState.comments.items.filter(comment => comment.new).length
    },
    async encryptState() {
      try {
        const key = await getEncryptionKey()

        if (key) {
          const currentState = await store.dispatch('getState')
          const lf = await store.dispatch('getLocalForage')

          if (!currentState.auth?.user.iv && currentState.auth?.user.email) {
            currentState.auth.user = await encryptData(key, currentState.auth.user)
          }

          if (!currentState.encounters?.items.iv) {
            currentState.encounters.items = await encryptData(key, currentState.encounters.items)
          }

          if (!currentState.facilities?.items.iv) {
            currentState.facilities.items = await encryptData(key, currentState.facilities.items)
          }

          if (!currentState.patients?.items.iv) {
            currentState.patients.items = await encryptData(key, currentState.patients.items)
          }

          if (!currentState.comments?.items.iv) {
            currentState.comments.items = await encryptData(key, currentState.comments.items)
          }

          if (!currentState.users?.items.iv) {
            currentState.users.items = await encryptData(key, currentState.users.items)
          }

          await lf.setItem('vuex', currentState)
          Vue.store.commit('setDecrypted', false)
        }
      } catch (error) {
        console.log('Error on encryptState', error)
      }
    },
    getLocalForage() {
      return localForageStore
    },
    loading({ commit }, value) {
      commit('SET_LOADING', value)

      return true
    },

    notify(
      { commit },
      {
        type = 'snackbar', value, timeout = 4000, color = 'info',
      },
    ) {
      if (type === 'snackbar') {
        commit('activateSnackbar', {
          value,
          timeout,
          color,
        })

        return new Promise(resolve => {
          setTimeout(() => resolve(true), timeout)
        })
      }

      return true
    },

    resetState({ commit }) {
      return Promise.all([
        commit('patients/RESET_STATE'),
        commit('encounters/RESET_STATE'),
        commit('facilities/RESET_STATE'),
        commit('users/RESET_STATE'),
        commit('comments/RESET_STATE'),
        commit('auth/RESET_STATE'),
        vuexLocal.storage.clear(),
        indexedDB.deleteDatabase('swc-store'),
        indexedDB.deleteDatabase('full-encounters'),
        indexedDB.deleteDatabase('full-patients'),
        localStorage.clear(),
        caches.keys().then(list => list.map(key => caches.delete(key))),
      ]).then(() => {
        Vue.store.dispatch('updateApp')
      })
    },

    // JMC: I'm disabling the window.location.reload() here
    // since it overrides the dialog shown when storage has been reset.
    // I've added page reloads to the methods calling this which is actually
    // just the resetState() above and the update button on the login screen.
    updateApp() {
      sessionStorage.clear()
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(registrations => Promise.all(registrations.map(registration => registration.unregister())))
          // .then(() => window.location.reload())
      } else {
        // window.location.reload()
      }
    },
    getState({ state }) {
      // Return stored modules.
      return {
        auth: state.auth,
        users: state.users,
        patients: state.patients,
        facilities: state.facilities,
        encounters: state.encounters,
        comments: state.comments,
      }
    },
    getLocalForageKeys() {
      return new Promise((resolve, reject) => {
        localForageStore.keys()
          .then(keys => {
            resolve(keys)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Runs on every page refresh
    loadData({ dispatch, state }) {
      if (!state.online) return false

      // Do not proceed if going to the login page.
      router.afterEach((to) => {
        if (to.name === 'login') {
          return false
        }
      })

      // If base data not loaded, sync all
      if (!Vue.store.getters['encounters/baseDataLoaded']) {

        // Record the start time
        const startTime = new Date();

        setTimeout(() => {
          Vue.store.dispatch('encounters/syncingModal', true)
          Vue.store.dispatch('auth/refresh')
          Vue.store.dispatch('syncAll', { pos: 0, cnt: 2 }).then((response) => {
            // Check if syncAll was successful (if response is true)
            if (response === true) {
              // Calculate the time taken
              const endTime = new Date();
              const timeTaken = (endTime - startTime) / 1000; // in seconds
              const minutes = Math.floor(timeTaken / 60);
              const seconds = Math.floor(timeTaken % 60);
              Vue.store.dispatch('encounters/syncingModal', false)
              Vue.store.dispatch('notify', { value: `Sync completed successfully in ${minutes} minutes and ${seconds} seconds.` })
            } else {
              // console.error('Error during initial sync: syncAll did not return true.', response)
              Vue.store.dispatch('encounters/syncingModal', false)
              Vue.store.dispatch('notify', { value: 'Initial Sync failed. Please try again.', color: 'error' })
            }
          })
            .catch((error) => {
              // console.error('Error during initial sync:', error)
              Vue.store.dispatch('encounters/syncingModal', false)
              Vue.store.dispatch('notify', { value: 'Initial Sync failed. Please try again.', color: 'error' })
            })
        }, 2000)
      } else {
        return Promise.all([
          dispatch('auth/refresh'),
          dispatch('comments/loadComments'),
        ])
      }

      return true
    },

    async syncAll({ dispatch, state }, syncProgress ) {
      if (!state.online) return false
      let pos = syncProgress.pos
      let cnt = syncProgress.cnt
      let message = 'There was an error while fetching data.<br> Below you can find the stages which failed to sync:<br><br><p style=font-size:11px;>'
      let failedSyncs = 0

      const actions = [
        'patients/loadPatients',
        'encounters/loadEncounters',
        'facilities/loadFacilities',
        'users/loadUsers',
        'comments/loadComments',
      ];

      for (const action of actions) {
        const response = await dispatch(action)

        if (response !== true && typeof response === 'string') {
          failedSyncs += 1
          message += `${response}<br>`
        }

        // Check if the current action is one of the specified actions
        if (action === 'patients/loadPatients' || action === 'encounters/loadEncounters') {
          await Vue.store.dispatch('encounters/syncingProgress', { pos: ++pos, cnt })
        }
      }
      message += '</p>'
      if (message.toLowerCase().includes('network error')) message += '<hr><br>Please check your internet connection and try to sync again. If the problem persists, contact IT Support.'

      if (failedSyncs > 0) return message

      return true // Return true if all actions are successful
    },
    async syncLogPost({ commit }, data) {
      return Vue.axios.post('sync-log.json', data)
        .then(response => response.data)
        .catch(e => Vue.prototype.$custom.processCommError(e, 'Save sync log'))
    },
  },
  modules: {
    ...modules,
    appConfig: appConfigStoreModule,
    app,
  },
  plugins: [vuexLocal.plugin],
})

Vue.store = store

export default store
