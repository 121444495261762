/* eslint-disable no-unused-vars */
import { ErrorMessage } from '@/@core/utils/wordings'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'

const bcrypt = require('bcryptjs')

const initialState = {
  redirectOnLogin: '',
  user: {
    name: '',
    email: '',
    type: '',
    remember: false,
    darkMode: false,
    avatar: '',
    place_of_services: [],
    quick_entry_emails: [],
    recentlySyncedFacilities: [],
    facility_playlists: [],
  },
  loading: false,
  sessionExpiration: 0,
}

// Accepts: array of objects
// Formats facility to list of integers for post request

function prepPayload(facilityList) {
  return {
    userID: Vue.auth.user().id,
    facilityID: facilityList.map(e => e.id),
    syncLogData: facilityList[0].sync_log,
  }
}

Object.defineProperties(Vue.prototype, {
  $authUser: {
    get() {
      return {
        user() { return Vue.auth.user() ?? false },
        id() { return Vue.auth.user() ? Vue.auth.user().id : false },
        userId() { return Vue.auth.user() ? Vue.auth.user().id : false },
        profile() { return Vue.auth.user() ? Vue.auth.user().user_profile : false },
      }
    },
  },
})

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  computed: {
    ...mapGetters(['isOnline']),
  },
  getters: {
    getSuffix(state, getters) {
      const user = getters.user
      const suffix = user?.user_profile?.health_professional_type
      return suffix ? suffix : ''
    },
    getField,
    getFacilityById(state) {
      return (id, idField = 'id') => state.user.place_of_services.find(item => item[idField] === id) || {}
    },
    getQuickEntryEmailsById(state) {
      return (id, idField = 'place_of_service_id') => state.user.quick_entry_emails.find(item => item[idField] === id) || {}
    },

    validSession: () => Vue.auth.user(),

    facilities(state) {
      return state.user.place_of_services
    },
    recentlySyncFacilities(state) {
      return state.user.recentlySyncedFacilities
    },
    facilityPlaylist(state) {
      return state.user.facility_playlists
    },
    quickEntryEmails(state) {
      return state.user.quick_entry_emails
    },
    user(state, getters) {
      // If we're online, get the user data from the auth plugin, otherwise
      // use what's saved in the store.
      const user = getters.isOnline ? Vue.auth.user() : state.user

      return user ? {
        ...user,
        name: `${user?.first_name} ${user?.last_name}`,
        avatar: user?.avatar || '1',
        type: user?.is_superuser ? 'Admin' : 'User',
      } : false
    },
    getSessionExpiration(state) {
      return state.sessionExpiration
    },
  },
  mutations: {
    updateField,
    updateQuickEntryEmails: (state, value) => {
      // Find the user's quick entry index if it exists for this facility
      const index = state.user.quick_entry_emails.findIndex(x => x.place_of_service_id === value.id)

      if (index !== -1) {
        // Update user's quick entry email addresses for this facility
        state.user.quick_entry_emails[index].email_addresses = value.email_addresses
      } else {
        // Create user's quick entry for this facility
        state.user.quick_entry_emails.push({
          place_of_service_id: value.id,
          email_addresses: value.email_addresses,
          user_id: state.user.id,
        })
      }
    },
    SET_RECENTLY_SYNCED_FACILITIES: (state, facilities) => {
      // state.user.recentlySyncedFacilities = []
      // return
      let toSyncFacilities = facilities
      const { recentlySyncedFacilities } = state.user

      // Remove facility that already exists in the recentlySyncedFacilities array
      toSyncFacilities = toSyncFacilities
        .filter(
          facility => !recentlySyncedFacilities
            .some(recentlySyncedFacility => recentlySyncedFacility.id === facility.id),
        )
      if (!state.user.recentlySyncedFacilities) {
        state.user.recentlySyncedFacilities = []
      }

      state.user.recentlySyncedFacilities.push(...toSyncFacilities)

      // Calculate the date two weeks ago
      const twoWeeksAgo = new Date()
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14)

      // Filter and sort state.user.recentlySyncedFacilities, then assign it
      state.user.recentlySyncedFacilities = state.user.recentlySyncedFacilities
        .filter(facility => {
          // eslint-disable-next-line no-underscore-dangle
          const lastSync = new Date(facility._joinData.created)

          // Include facilities synced within the last two weeks
          return lastSync >= twoWeeksAgo
        })
    },
    SET_USER_LOGIN: (state, value) => {
      Object.keys(value).forEach(key => {
        state.user[key] = value[key]
      })
    },
    SET_REDIRECT_ON_LOGIN: (state, value) => {
      state.redirectOnLogin = value
    },
    SET_USER_REMEMBER: (state, value) => {
      state.user.remember = value
    },
    SET_DARK_MODE: (state, value) => {
      state.user.darkMode = value
    },
    SET_LOADING: (state, value) => {
      state.loading = value
    },
    RESET_STATE: state => {
      const { remember, email, darkMode } = state.user
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
      state.user = {
        ...initialState.user,
        darkMode,
        remember,
        email: remember ? email : '',
      }
      sessionStorage.clear()
    },
    SET_SESSION_EXPIRATION: (state, value) => {
      state.sessionExpiration = Number.isNaN(value) ? 0 : value
    },
    SET_MY_FACILITY_PLAYLIST: (state, value) => {
      let isFound = false;
      state.user.facility_playlists.map((playlist, index) => {
        if (playlist.id === value.id) {
          isFound = true;
          Vue.set(state.user.facility_playlists, index, {
            ...playlist,
            ...value,
          });
        }
      });

      if (!isFound) {
        state.user.facility_playlists.push({
          ...value
        });
      }
    },
    REMOVE_MY_FACILITY_PLAYLIST: (state, id) => {
      state.user.facility_playlists = state.user.facility_playlists.filter(playlist => playlist.id !== id)
    },
  },
  actions: {
    async login({ state, commit }, { email, password, remember }) {
      commit('SET_LOADING', true)
      commit('SET_USER_REMEMBER', remember)

      const DEBUG = false
      // eslint-disable-next-line eqeqeq
      if (!navigator.onLine) {
        return new Promise((resolve, reject) => {
          bcrypt.compare(password, state.user.password, (err, res) => {
            if (res === true) {
              Vue.store.dispatch('encounters/setSynced', false)
              console.log('passwords matched!')
              Vue.auth.token(null, 'MY-TOKEN_CODE', false)
              Vue.auth.user(state)
              Vue.store.dispatch('auth/updateRecentlySyncedFacilities')

              return resolve({ success: true, redirect: { name: state.redirectOnLogin || 'home' } })
            }

            return reject(new Error('fail'))
          })
        }).catch(e => { console.log(`error catch: ${e}`); Vue.prototype.$custom.processCommError(e, 'login', true) })
          .finally(() => { commit('SET_LOADING', false) })
      }

      return Vue.auth
        .login({
          data: {
            email,
            password,
          },
          remember,
          staySignedIn: remember,
        })
        .then(async response => {
          // Decrypt data after successful login.
          await Vue.store.dispatch('decryptState')
          if (remember) {
            Vue.auth.remember(
              JSON.stringify({
                email,
              }),
            )
          }

          // When signing on, set isSynced status to false
          await Vue.store.dispatch('encounters/setSynced', false)
          await Vue.store.dispatch('auth/updateRecentlySyncedFacilities')

          return { success: true, redirect: { name: state.redirectOnLogin || 'home' } }
        })
        .catch(e => { console.log(`error catch: ${e}`); Vue.prototype.$custom.processCommError(e, 'login', true) })
        .finally(() => commit('SET_LOADING', false))
    },
    refresh({ commit }) {
      return Vue.auth.refresh().then(response => {
        const now = Math.floor(Date.now() / 1000)

        // console.log(`Update Session: ${new Date().toTimeString().slice(0, 8)}`, now, response.data.expires, response.data.expires - response.data.created)
        const expires = now + (response.data.expires - response.data.created)
        commit('SET_SESSION_EXPIRATION', expires)
      }).catch(error => {
        console.error('Error refreshing session:', error)
        throw error
      })
    },
    fetch() {
      return Vue.auth.fetch()
    },
    // eslint-disable-next-line no-unused-vars
    async logout({ commit }) {
      // commit('RESET_STATE')
      try {
        // Don't do a redirect if already on the login page
        if (Vue.router.history.current.name !== 'login') {
          await Vue.router.push({ name: 'login' })
        }
      } catch (error) {
        console.error('Error on logout method', error)
        throw error
      }
    },
    setDarkMode({ commit }, value) {
      commit('SET_DARK_MODE', value)
    },
    setLoading({ commit }, value) {
      commit('SET_LOADING', value)
    },
    removeFacilities({ commit }, facilityList) {
      return Vue.axios.delete(`user/${Vue.auth.user().id}/place-of-service`, { data: prepPayload(facilityList) })
        .then(response => ((response.data.status === 'Error' && response.data.message)
          ? response.data
          : response.data.status === 'Success'))
        .catch(e => Vue.prototype.$custom.processCommError(e, 'deleting facility from go list'))
    },
    addFacilities({ commit }, facilityList) {
      return Vue.axios.post(`user/${Vue.auth.user().id}/place-of-service`, prepPayload(facilityList))
        .then(response => ((response.data.status === 'Error' && response.data.message)
          ? response.data
          : response.data.status === 'Success'))
        .catch(e => Vue.prototype.$custom.processCommError(e, 'adding facility to go list'))
    },
    async syncFacilityPlaylist({ commit }, facilityPlaylist) {
      try {
        const method = facilityPlaylist.id ? 'patch' : 'post'

        if (facilityPlaylist?.place_of_services?.length > 0) {
          delete facilityPlaylist.place_of_services
        }

        const response = await Vue.axios[method](`user/${Vue.auth.user().id}/save-facility-playlist`, facilityPlaylist)
        if ([200, 201].includes(response.status)) {
          Vue.prototype.$custom.showSuccessNotification(response.data.message)
          commit('SET_MY_FACILITY_PLAYLIST', response.data.response)
          return true
        }
      } catch (error) {
        console.log(error)
        const message = error.response?.data?.message || ErrorMessage

        Vue.prototype.$custom.processCommError({ message }, 'adding facility playlist', true)
      }

      return false
    },
    async deleteFacilityPlaylist({ commit }, data) {
      try {
        const response = await Vue.axios.delete(`user/${Vue.auth.user().id}/delete-facility-playlist/`, {
          data: {
            id: data.playlistId,
            sync_log: data.syncLog,
          },
        })

        if (response.status === 200) {
          Vue.prototype.$custom.showSuccessNotification(response.data.message)
          commit('REMOVE_MY_FACILITY_PLAYLIST', data.playlistId)
          return true
        }
      } catch (error) {
        const message = error.response?.data?.message || ErrorMessage

        Vue.prototype.$custom.processCommError({ message }, 'deleting facility playlist', true)
      }
    },
    async useFacilityPlaylist({ commit }, data) {
      try {
        const response = await Vue.axios.patch(`user/${Vue.auth.user().id}/use-facility-playlist/`, {
          id: data.playlistId,
          sync_log: data.syncLog,
        })

        if (response.status === 200) {
          // Vue.prototype.$custom.showSuccessNotification(response.data.message)
          return true
        }
      } catch (error) {
        const message = error.response?.data?.message || ErrorMessage

        Vue.prototype.$custom.processCommError({ message }, 'using facility playlist', true)
      }
    },
    updateRecentlySyncedFacilities({ state, commit }) {
      const lastTwoWeeks = new Date()
      lastTwoWeeks.setDate(lastTwoWeeks.getDate() - 14)
      const recentlySynced = state.user.place_of_services.filter(facility => {
        // eslint-disable-next-line no-underscore-dangle
        const lastSync = new Date(facility._joinData.created)

        return lastSync >= lastTwoWeeks
      })
      if (recentlySynced.length > 0) {
        commit('SET_RECENTLY_SYNCED_FACILITIES', recentlySynced)
      }
    },
  },
}
